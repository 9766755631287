<template>
  <Card class="allSearchCard">
    <template v-if="type === 'account'">
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.name}}
            <!-- <span class="tips"> {{cardInfo.gender}}</span> -->
            <img
              style="width:20px;height:auto;"
              src="../../../../assets/images/icon/male.png"
              alt="mobile"
              v-if="cardInfo.gender === '1'"
            >
            <img
              style="width:20px;height:auto;"
              src="../../../../assets/images/icon/girl.png"
              alt="mobile"
              v-if="cardInfo.gender === '2'"
            >
          </h3>
          <!-- <span class="tag">{{cardInfo.tag}}</span> -->
        </div>
      </template>
      <div class="description">
        <h4 class="accountMobile">
          <Icon name="phone" class="cu-phone" />{{cardInfo.phone}}
        </h4>
      </div>
      <template slot="footer">
        <span class="msg" style="line-height: 32px;">
          {{cardInfo.source}}
        </span>
        <div style="display: flex;">
          <Icon
            name="chat-o"
            @click="wxDoChat(cardInfo.eosAccountId)"
            size="30"
            color="#1899FF"
            class="cu-chat-o"
          />
          <Button
            round
            type="info"
            size="small"
            @click="detailsaccount(cardInfo.eosAccountId)"
          >详情</Button>
        </div>
      </template>
    </template>
    <template v-if="type === 'leads'">
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.qbsAccountName}}
            <!-- <span class="tips">{{cardInfo.gender}}</span> -->
            <img
              style="width:20px;height:auto;"
              src="../../../../assets/images/icon/male.png"
              alt="mobile"
              v-if="cardInfo.gender === '1'"
            >
            <img
              style="width:20px;height:auto;"
              src="../../../../assets/images/icon/girl.png"
              alt="mobile"
              v-if="cardInfo.gender === '2'"
            >
          </h3>
          <span class="tag" >{{cardInfo._status}}</span>
        </div>
      </template>
      <div class="description">
        <h4 class="accountMobile">
          <Icon name="phone" class="cu-phone" />{{cardInfo.qbsAccountPhone}}
        </h4>
        <h4 class="accountMobile"> 最近跟进：{{handletime(cardInfo.qbsFollowupTime)}}</h4>
        <h4 class="accountMobile qbsFollowupMessage"> 跟进信息：{{cardInfo.qbsFollowupMessage}}</h4>
      </div>
      <Row>
        <Cols span="18">
          <CardCell
            v-for="($item, ind)  in cardInfo.info"
            :key="ind"
            :label="$item.name"
            :value="$item.val"
            label-width="1.5rem"
          />
        </Cols>
      </Row>
      <template slot="footer">
        <span class="msg">
          <Icon name="manager" class="cu-manager" />{{cardInfo.ownerUserName}}
        </span>
        <div style="display: flex;">
          <Icon
            name="chat-o"
            @click="wxDoChat(cardInfo.eosAccountId)"
            size="30"
            color="#1899FF"
            class="cu-chat-o"
          />
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone-circle-o"
              size="30"
              color="#1899FF"
              class="cu-phone-circle-o"
            />
          </a>
          <Button
            round
            type="info"
            size="small"
            @click="detailsaccount(cardInfo.eosAccountId)"
          >详情</Button>
          <!-- 此处逻辑 无归属人的显示认领按钮/有归属人且状态不为已转化的显示跟进按钮/有归属人且状态为已转化的不显示跟进按钮-->
          <Button
            round
            type="info"
            size="small"
            @click="fllowupleader(cardInfo.eosLeadsId)"
            v-if="cardInfo.eosOwnerUserId && cardInfo.status !== 'CONVERTED'"
          >跟进</Button>
          <Button
            round
            v-if="!cardInfo.eosOwnerUserId"
            type="info"
            size="small"
            @click="receive(cardInfo)"
          >认领</Button>
        </div>
      </template>
    </template>
    <template v-if="type === 'opp'">
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.qbsAccountName}}<span
              v-if="cardInfo.closedate"
              class="tips"
            >Close Date :{{handletime(cardInfo.closedate)}}</span></h3>
          <span class="tag">{{cardInfo._status}}</span>
        </div>
      </template>
      <div class="description">
        <h4 class="accountMobile">
          <Icon name="phone" class="cu-phone" />{{cardInfo.qbsAccountPhone}}
        </h4>
        <h4 class="accountMobile"> 最近跟进：{{handletime(cardInfo.qbsFollowupTime)}}</h4>
        <h4 class="accountMobile qbsFollowupMessage"> 跟进信息：{{cardInfo.qbsFollowupMessage}}</h4>
      </div>
      <Row>
        <Cols span="18">
          <CardCell
            v-for="($item, ind)  in cardInfo.info"
            :key="ind"
            :label="$item.name"
            :value="$item.val"
            label-width="1.5rem"
          />
        </Cols>

      </Row>
      <template slot="footer">
        <span class="msg">
          <Icon name="manager" class="cu-manager" />{{cardInfo.ownerUserName}}
        </span>
        <div style="display: flex;">
          <Icon
            name="chat-o"
            @click="wxDoChat(cardInfo.eosAccountId)"
            size="30"
            color="#1899FF"
            class="cu-chat-o"
          />
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone-circle-o"
              size="30"
              color="#1899FF"
              class="cu-phone-circle-o"
            />
          </a>
          <Button
            round
            type="info"
            size="small"
            @click="detailsaccount(cardInfo.eosAccountId)"
            style="margin-right: 10px;"
          >详情</Button>
          <Button
            round
            type="info"
            size="small"
            @click="detailOpp(cardInfo.eosOppId)"
          >跟进</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Row, Col, Dialog, Toast } from 'vant'
import { taokeClaim } from '@/api/chargequery'
import { orgCode } from '@/api/chargequery'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
import 'vant/lib/icon/style'
import 'vant/lib/row/style'
import 'vant/lib/col/style'
import '@/style/vant-patch.less'
import './index.less'
import dayjs from 'dayjs'
import '@/style/repairIcon.less'
import { wxDoChat } from '@/utils/wechart'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      wxDoChat,
    }
  },
  components: {
    Card,
    Button,
    CardCell,
    Icon,
    Row,
    Cols: Col
  },
  methods: {
    init () {
      this.handletime()
    },
    handletime (time) {
      return time ? dayjs(time).format('YYYY/MM/DD') : ''
    },
    // 认领按钮 
    receive (info) {
      Dialog.confirm({
        title: info.qbsAccountName,
        message: info.qbsAccountPhone,
      }).then(() => {
        this.taokeClaim(info.eosLeadsId)
      }).catch(() => {
        // on cancel
      })
    },
    // 认领
    async taokeClaim (eosLeadsId) {
      const res = await taokeClaim({ leadsId: eosLeadsId })
      if (res.code === '000000') {
        Toast('认领成功')
        this.$emit('Claimsuccess')
      } else {
        Toast(res.msg)
      }
    },
    // account跳转详情
    detailsaccount (accountId) {
      this.$router.push({
        path: '/customerInfo',
        query: { 'id': accountId }
      })
    },
    //leader跟进
    fllowupleader (id) {
      this.$router.push({ path: '/cluesFollowUpFrom', query: { eosLeadsId: id } })
    },
    // Opp跳转详情
    detailOpp (id) {
      this.$router.push({
        path: '/businessOpportunitiesFollowUpFrom',
        query: { eosOppId: id }
      })
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.qbsAccountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('正在拨打')
      } else {
        Toast(res.msg)
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<template>
  <App
    left-arrow
    right-text="取消"
    @click-right="onClickRight"
    class="allSearch"
  >
    <div slot="title">
      <Search
        v-model="tel"
        placeholder="Please enter手机号"
        @search="onSearch"
        maxlength="11"
      />
    </div>
    <Tabs
      v-model="tabActive"
      @change="getData"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
        <PullRefresh
          v-show="hasDate"
          @refresh="onRefresh"
          v-model="loading"
        >
          <List
            v-model="loading"
            :finished="finished"
            finished-text="No more items."
            @load="getData"
          >
            <CardItem
              v-for="$items in list"
              @Claimsuccess="Claimsuccess"
              :key="$items.id"
              :cardInfo="$items"
              :type="tabActive"
            />
          </List>
        </PullRefresh>
      </Tab>
      <div v-show="!hasDate">
        <div class="no-customer">
          <div class="icon">
            <Icon
              name="manager"
              size="54"
            />
          </div>
          <div class="desc">没有能找到对应的客户</div>
          <div class="desc">您可以点击下方新建按钮进行新建</div>
          <Button
            type="primary"
            class="create"
            size="small"
            @click="cerated"
          >新建</Button>
        </div>
      </div>
    </Tabs>
  </App>
</template>

<script>
import { Search, Tab, Tabs, PullRefresh, List, Toast, Icon, Button } from 'vant'
import { LeadsSearch, accountSearch, oppSearch, getClueSource2 } from '@/api/chargequery'
import { tabsData } from './attr.js'
import mobile from '@/config/regexp.js'
import CardItem from './components/card/index'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'allSearch',
  data () {
    return {
      tel: '',
      hasDate: true,
      loading: false,
      finished: true,
      radioVal: '1',
      list: [],
      listAll: [],
      tabActive: 'account',
      tabsData: [],
      sourceList: []
    }
  },
  components: {
    Search,
    Tab,
    Tabs,
    CardItem,
    List,
    PullRefresh,
    Icon,
    Button
  },
  methods: {
    // 获取客户来源
    async getClueSource () {
      const res = await getClueSource2()
      if (res.success) {
        this.sourceList = res.data
      } else {
        Toast(res.msg)
      }
    },
    // 认领成功
    Claimsuccess () {
      this.onSearch()
    },
    async getData () {
      if (this.tel) {
        let res = {}
        if (this.tabActive === 'account') {
          // accountSearch
          res = this.listAll[0]
        }
        if (this.tabActive === 'leads') {
          // LeadsSearch
          res = this.listAll[1]
        }
        if (this.tabActive === 'opp') {
          // oppSearch
          res = this.listAll[2]
        }

        res.data.forEach(item => {

          this.$storage.get('baseData')['gende'].forEach(el => {
            // if (item['gender'] === el.value) {
            //   item.gender = el.key
            // }
            if (item['qbsHibernateStatus'] === el.value) {
              item.qbsHibernateStatus = el.key
            }
          })
          if (this.tabActive === 'leads') {
            this.$storage.get('baseData')['leads_status'].forEach(leadsItem => {
              if (leadsItem.value === item.status) {
                item['_status'] = leadsItem.key
              }
            })
          }
          if (this.tabActive === 'opp') {
            this.$storage.get('baseData')['opp_status'].forEach(leadsItem => {
              if (leadsItem.value === item.status) {
                item['_status'] = leadsItem.key
              }
            })
          }
          this.sourceList.forEach(sourceItem => {
            if (item.source === sourceItem.threelevelCode) {
              item.source = sourceItem.threelevelName
            }
          })
        })
        this.list = res.data
        this.loading = false
      }
    },
    // 跳转到新建页面
    cerated () {
      if (mobile.num.reg.test(this.tel)) {
        this.$router.push({
          path: '/newCreateForm',
          query: {
            'tel': this.tel
          }
        })
      } else {
        Toast('请填写正确的手机号')
      }

    },
    init () {
      this.hasDate = true
      this.tel = ''
      this.list = []
      this.getClueSource()
      this.tabsData = tabsData
      this.tabActive = this.tabsData[0].label
    },
    onRefresh () {
      this.ist = []
      this.onSearch()
    },
    onSearch () {
      if (mobile.num.reg.test(this.tel)) {
        /* Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        }) */
        // 3个中只要有一个有数据就不展示新建
        Promise.all([
          accountSearch({ phone: this.tel }),
          LeadsSearch({ phone: this.tel }),
          oppSearch({ phone: this.tel })
        ]).then(res => {
          Toast.clear()
          this.listAll = [...res]
          if (res[0].data.length > 0 || res[1].data.length > 0 || res[2].data.length > 0) {
            this.hasDate = true
          } else {
            this.hasDate = false
          }
          this.getData()
        }).catch(err => {
          Toast.clear()
          Toast(err)
        })
      } else {
        Toast(mobile.num.msg)
      }
    },
    onClickRight () {
      this.tel = ''
      this.hasDate = true
      this.list = []
    }
  },
  mounted () {
    this.init()
  }
}
</script>
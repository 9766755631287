/*
 * @Author: zzz
 * @Date: 2021-01-26 15:49:46
 * @LastEditTime: 2021-01-28 16:56:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\rank\attr.js
 */
export const tabsData = [
  {
    title: 'Account',
    label: 'account'
  },
  {
    title: 'Leads',
    label: 'leads'
  },
  {
    title: 'Opp',
    label: 'opp'
  }
]
